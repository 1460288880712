<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <el-button
        type="primary"
        @click="$router.go(-1)"
        style="margin-left: 95%; margin-bottom: 20px"
      >
        返回</el-button
      >
      <!--搜索条件区域-->
      <div
        class="searchWrapper bgFFF marginB20"
        @keydown.enter.prevent="searchData"
      >
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <el-form-item label="人员">
            <el-select
              filterable
              :maxlength="20"
              v-model="formInline.creatorId"
              placeholder="请选择"
            >
              <el-option label="请选择" value=""></el-option>
              <el-option
                v-for="item in oprList"
                :key="item.bossManagerId"
                :label="item.bossManagerName"
                :value="item.bossManagerId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间">
            <timeRangePick
              @timeChange="timeChange"
              ref="timeRangePicker"
              :defalutDate="defalutDate"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                page = 1;
                searchData();
              "
              :loading="loading"
              >查询
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          stripe
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          >
          </el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper" v-show="total > 0">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import timeRangePick from "@/components/timePicker";
export default {
  name: "parkingCardManagementLog",
  data() {
    return {
      formInline: {
        creatorId: "",
        startTime: "", // 时间段
        endTime: "",
      },
      defalutDate: [],
      loading: false,
      oprList: [],
      tableCols: [
        { prop: "content", label: "日志内容", width: "" },
        { prop: "creatorName", label: "人员", width: "" },
        { prop: "createdTime", label: "时间", width: "" },
      ],
      page: 1,
      pageSize: 15,
      total: 0,
      tableData: [],
    };
  },
  components: {
    timeRangePick,
  },
  methods: {
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10
          ? "0" + (nowDate.getMonth() + 1)
          : nowDate.getMonth() + 1;
      let day =
        nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    searchData() {
      let url = "/acb/2.0/parkCardLog/list";
      let data = {
        page: this.page,
        pageSize: this.pageSize,
        bizType: 2, // 1停车卡管理日志
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        creatorId: this.formInline.creatorId,
        bizId: this.$route.query.orderId,
      };
      // console.log(data, "data--------");
      this.$axios
        .get(url, {
          data: data,
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, "提示");
          }
        })
        .catch((_) => {
          this.total = 0;
          this.loading = false;
        });
    },
    getOprList() {
      this.$axios
        // .get("/acb/2.0/bossManager/getManagerListByAreaId")
        .get("/acb/2.0/bossManager/listVO", {
          data: {
            page: 1,
            size: 10000,
          },
        })
        .then((res) => {
          this.oprList = res.value.list;
        });
    },
  },
  created() {
    this.defalutDate = [
      this.dataTimeRest() + " 00:00:00",
      this.dataTimeRest() + " 23:59:59",
    ];
    this.searchData();
    this.getOprList();
  },
};
</script>
  <style scoped lang="stylus" rel="stylesheet/stylus">
  .content {
    overflow: hidden;

    .searchWrapper {
      overflow: hidden;
      padding: 22px 22px 0;
      margin-bottom: 20px;
    }
  }
</style>
